<template>
  <TitleSection />
  <div class="aboutBG">
    <LiffyStory />
    <LTRslide class="aboutGap" />
    <Branding class="aboutGap" />
    <RTLslide class="aboutGap" />
  </div>
  <FootPrint class="aboutGap aboutHistoryBG" />
  <GetInTouch class="aboutSection" />
</template>

<script>
import Branding from '../about/Branding.vue';
import FootPrint from '../about/FootPrint.vue';
import LiffyStory from '../about/LiffyStory.vue';
import LTRslide from '../about/LTRslide.vue';
import RTLslide from '../about/RTLslide.vue';
import GetInTouch from '../common/GetInTouch.vue';
import TitleSection from '../common/TitleSection.vue';


export default {
  name : 'about',
  data(){
    return{
      
    }
  },
  components: {
    TitleSection,
    LiffyStory,
    LTRslide,
    Branding,
    RTLslide,
    FootPrint,
    GetInTouch
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    }
  },
  props : {
    blogMsg : Array,
  }
}
</script>

<style>
.aboutBG {
  background-image: url("../../assets/image/pattern/pattern1.png");
  padding-top: 220px;
}
.aboutGap {
  margin-top: 150px;
}
.aboutHistoryBG {
  background-image: url("../../assets/image/pattern/pattern2.png");
  background-repeat: repeat-y;
  background-position-x: center;
  padding-bottom: 220px;
}
@media screen and (max-width: 490px) {
  .aboutBG {
    padding-top: 105px;
  }
  .aboutGap {
    margin-top: 80px;
  }
  .aboutHistoryBG {
    padding-bottom: 105px;
  }
}
</style>