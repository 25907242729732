<template>
  <div>
    <div class="LTRslideWrap">
      <ul class="LTRimgSlide LTRoriginal">
        <li v-for="(item, index) in LTRoriginalSlides" :key="index" class="LTRlistPosition">
          <SlideCard :SlideCardImg="item.image" :SlideCardSide="item.side"/>
        </li>
      </ul>
      <ul class="LTRimgSlide LTRclone">
        <li v-for="(item, index) in LTRclonedSlides" :key="index" class="LTRlistPosition">
          <SlideCard :SlideCardImg="item.image" :SlideCardSide="item.side"/>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import SlideCard from './SlideCard.vue';

export default {
  name: 'LTRslide',
  components: {
    SlideCard
  },
  data() {
    const LTRoriginalSlides = ref([
      {
        id: 1,
        side: 'slide_top',
        image: require('../../assets/image/creator/creator_1.jpg')
      },
      {
        id: 2,
        side: 'slide_bottom',
        image: require('../../assets/image/creator/creator_2.jpg')
      },
      {
        id: 3,
        side: 'slide_top',
        image: require('../../assets/image/creator/creator_3.jpg')
      },
        {
        id: 4,
        side: 'slide_bottom',
        image: require('../../assets/image/creator/creator_4.jpg')
      },
      {
        id: 5,
        side: 'slide_top',
        image: require('../../assets/image/creator/creator_5.jpg')
      },
      {
        id: 6,
        side: 'slide_bottom',
        image: require('../../assets/image/creator/creator_6.jpg')
      }   
    ]);
    const LTRclonedSlides = ref([...LTRoriginalSlides.value]);

    onMounted(() => {
      // 복제본 추가
      document.querySelector('.LTRslideWrap').appendChild(document.querySelector('.LTRclone'));

      // 원본, 복제본 위치 지정
      // document.querySelector('.LTRimgSlide').style.width = document.querySelector('.LTRimgSlide').offsetWidth + 'px';
      document.querySelector('.LTRimgSlide').style.width = '2580px'; // 이래도 괜찮은건가
    });

    return {
      LTRoriginalSlides,
      LTRclonedSlides
    };
  }
};
</script>

<style>
/* reset */
section { width: 100%; }
li { display: inline-block; list-style: none; }

/* 슬라이드 */
.LTRslideWrap {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  height: 416px;
  overflow: hidden;
}
.LTRslideWrap .LTRimgSlide {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0;
}
.LTRslideWrap .LTRimgSlide .LTRlistPosition {
  position: relative;
}
.LTRslideWrap .LTRimgSlide.LTRoriginal {
  animation: 40s linear 0s infinite reverse forwards running LTRslide01;
}
.LTRslideWrap .LTRimgSlide.LTRclone {
  animation: 40s linear 0s infinite reverse none running LTRslide02;
}
.LTRslideWrap .LTRimgSlide li {
  width: 400px;
  height: 416px;
  /* line-height: 360px; */
  margin-right: 20px;
  text-align: center;
}

@media screen and (max-width: 490px) {
  .LTRslideWrap {
    height: 200px;
  }
  .LTRslideWrap .LTRimgSlide li {
    width: 180px;       /* 각각의 영역에 대한 너비 */
    height: 185px;      /* 상하단 여백 포함 높이 */
    /* line-height: 160px; */
    margin-right: 8px;
  }
}

/* 애니메이션 */
@keyframes LTRslide01 {
  0% { transform: translateX(0); }
  50% { transform: translateX(-100%); }
  50.01% { transform: translateX(100%); }
  100% { transform: translateX(0); }
}
@keyframes LTRslide02 {
  0% { transform: translateX(0); }
  100% { transform: translateX(-200%); }
}



</style>