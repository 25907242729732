<template>
  <div>
    <div class="d-flex w-100 flex-column contact_bg">
      <div class="container contact_container">
        <div class="contact_bgImage">
          <div class="contact_mainImage"></div>
        </div>

        <div class="contact_inputArea">
          <div class="contact_input_title"><p>Contact us</p></div>
          <form @submit.prevent="sendEmail">
            <div class="contact_input_group">
              <div><label for="author">Name</label></div>
              <input type="text" id="author" v-model="author" required />
            </div>

            <div class="contact_input_group">
              <div><label for="address">Email</label></div>
              <input type="email" id="address" v-model="address" required />
            </div>

            <div class="contact_input_group">
              <div><label for="context">Message</label></div>
              <textarea id="context" v-model="context" required></textarea>
            </div>

            <!-- <div class="g-recaptcha" :data-sitekey="siteKey"></div> -->

            <div class="contact_input_submitDiv">
              <button class="contact_input_submit" type="submit">Submit</button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';

export default {
  name: 'contact',
  data() {
    return {
      address: '',
      author: '',
      context: '',
      // siteKey: 'SITE_KEY'
    };
  },
  methods: {
    async sendEmail() {
      // // recaptcha Process // //
      // const recaptchaResponse = grecaptcha.getResponse(); //reCAPTCHA
      // if (!recaptchaResponse) {
      //   alert('reCAPTCHA를 완료해주세요.');
      //   return;
      // }

      // // 이게 돌아가던거! // //
      // axios.post('http://localhost:8080/send-email', {
      //   address: this.address,
      //   author: this.author,
      //   context: this.context,
      //   recaptchaResponse
      // })
      // .then(() => {
      //   alert('이메일이 성공적으로 전송되었습니다!');
      // })
      // .catch(error => {
      //   alert('이메일 전송 실패: ' + error.message);
      // });

      // CORS 조작으로 보안성 높이기 //
      try {
        // await axios.post('http://localhost:8080/send-email', {
        await axios.post('http://liffy.net/send-email', {
          address: this.address,
          author: this.author,
          context: this.context,
          // recaptchaResponse
        }, {
          headers: {
            'Content-Type': 'application/json', // 필요한 Content-Type 설정
          }
        });
        alert('이메일이 성공적으로 전송되었습니다!');
        this.address = '';
        this.author = '';
        this.context = '';
      } catch (error) {
        alert('이메일 전송 실패: ' + error.message);
      }
    }
  }
};
</script>

<style>
.contact_bg {
  text-align: left;
  background-color: #87ea5c;
  background-image: url("../../assets/image/pattern/pattern1_w.png");
  padding-top: 96px;
  padding-bottom: 64px;
}
.contact_container {
  flex-direction: row;
}
.contact_mainImage {
  width: 560px;
  height: 720px;
  border-radius: 20px;
  border : solid 6px white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url('../../assets/image/service/OurService_Card1.jpg');
}
.contact_inputArea {
  width: calc(100% - 624px);
  margin-left: 64px;
}
.contact_input_title {
  font-size: 70px;
  font-weight: 900;
  margin-bottom: 24px;
}
.contact_input_group {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 24px;
}
.contact_input_group div {
  margin-bottom: 12px;
}
.contact_input_group input {
  width: 100%;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  padding: 16px 12px;
}
.contact_input_group textarea {
  width: 100%;
  height: 160px;
  font-size: 16px;
  border: none;
  border-radius: 15px;
  padding: 12px;
  resize: none;
}
.contact_input_group textarea::-webkit-scrollbar {
  width: 24px;
}
.contact_input_group textarea::-webkit-scrollbar-thumb {
  background-color: rgba(8,52,0,1);
  border-radius: 50px; 
  border: 8px solid rgba(255,255,255,1);
}
.g-recaptcha {
  margin-bottom: 28px;
  text-align: -webkit-right;
}
.contact_input_submitDiv {
  text-align: -webkit-right;
}
.contact_input_submit {
  width: 240px;
  height: 48px;
  background-color: #083400;
  border-radius: 50px;
  border: none;
  font-size: 20px;
  font-weight: 700;
  color: white;
  right: 0;
  bottom: 0;
  transition: 0.3s;
}
.contact_input_submit:hover {
  background-color: #0c5000;
  color: #87ea5c;
}

@media screen and (max-width: 1200px) {
  .contact_container {
    flex-direction: column-reverse;
  }
  .contact_mainImage {
    width: 100%;
    height: 640px;
    margin-top: 88px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .contact_inputArea {
    width: 100%;
    margin-left: 0px;
  }
}
@media screen and (max-width: 490px) {
  .contact_input_title {
    font-size: 36px;
    margin-bottom: 24px;
  }
  .contact_input_group {
    font-size: 15px;
    margin-bottom: 16px;
  }
  .contact_input_group div {
    margin-bottom: 8px;
  }
  .contact_input_group input {
    font-size: 12px;
    border-radius: 5px;
    padding: 8px 8px;
  }
  .contact_input_group textarea {
    height: 88px;
    font-size: 12px;
    border-radius: 8px;
    padding: 8px;
  }
  .g-recaptcha {
    margin-bottom: 24px;
    text-align: -webkit-right;
  }
  .contact_input_submit {
    width: 200px;
    height: 40px;
    font-size: 18px;
  }
  .contact_mainImage {
    height: 400px;
    margin-top: 80px;
  }
}
</style>