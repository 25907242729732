<template>
  <div>
    <div class="d-flex w-100 flex-column bs_lf_bg">
      <div class="container">
        <div class="bg_lf_titleDiv">
          <p class="bs_lf_title">LIFFY</p>
          <p class="bs_lf_desc">{{ $t('business.liffySection.description') }}</p>
        </div>
      </div>
      <div class="container bs_lf_chipContainer">
        <div class="bs_lf_chipDiv">
          <div class="bs_lf_chipCase"><p class="bs_lf_chip">{{ $t('business.liffySection.chips.chipA') }}</p></div>
          <div class="bs_lf_chipCase"><p class="bs_lf_chip">{{ $t('business.liffySection.chips.chipB') }}</p></div>
          <div class="bs_lf_chipCase"><p class="bs_lf_chip">{{ $t('business.liffySection.chips.chipC') }}</p></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'LiffyTitle',
  data(){
    return{
      
    }
  }
}
</script>

<style>
.bs_lf_bg {
  background-image: url("../../assets/image/etc/bg_liffy.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 616px;
}
.bg_lf_titleDiv {
  width: 100%;
  text-align: left;
  padding-top: 40px;
}
.bs_lf_title {
  color: #87ea5c;
  font-size: 50px;
  font-weight: 900;
  margin-bottom: 12px;
}
.bs_lf_desc {
  color: #87ea5c;
  font-size: 25px;
  font-weight: 500;
  max-width: 824px;
}
.bs_lf_chipContainer {
  margin-top: auto;
}
.bs_lf_chipDiv {
  width: 100%;
  text-align: right;
}
.bs_lf_chipCase {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}
.bs_lf_chip {
  width: 140px;
  height: 40px;
  border-radius: 50px;
  background-color: #87ea5c;
  text-align: center;
  align-content: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

@media screen and (max-width: 1000px) {
  .bs_lf_desc {
    color: #87ea5c;
    font-size: 25px;
    font-weight: 500;
    max-width: 660px;
  }
  .bs_lf_chipDiv {
    width: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 490px) {
  .bs_lf_bg {
     height: 300px;
  }
 .bg_lf_titleDiv {
    padding-top: 16px;
  }
  .bs_lf_title {
    font-size: 20px;
    margin-bottom: 4px;
  }
  .bs_lf_desc {
    font-size: 12px;
  }
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .bs_lf_chip {
    width: 72px;
    height: 20px;
    border-radius: 20px;
    font-size: 11px;
  }
}
</style>