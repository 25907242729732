<template>
    <div class="socialContent">
        <a :href="socialLink" target="_blank">
            <img :src="`${socialLogo}`" class="socialLogo"/>
            <div class="socialTitle">{{socialTitle}}</div>
            <div class="socialBtn">View &gt;</div>
        </a>
    </div>

  <!-- <a :href="socialLink" class="bs-social-card-div" target="_blank">
    <div class="bs-social-card-type">
        <div :style="{ backgroundImage: `url(${socialLogo})` }"></div>
        <div class="socialTitle">{{socialTitle}}</div>
    </div>
    
    <div class="socialBtn">
        <div class="socialBtnDesc">View &gt;</div>
    </div>
  </a> -->
</template>

<script>
export default {
    name: 'SocialCard',
    data(){
        return{
            
        }
    },
    props: {
        socialTitle: {
            type: String,
            require: true
        },
        socialLogo: {
            type: String,
            require: true
        },
        socialLink: {
            type: String,
            require: true
        }
    }
}
</script>

<style>
.socialContent {
    width: 100%;
    position: relative;
    display: inline-block;
}
.socialContent .socialLogo {
    width: 50%;
    max-width: 200px;
    min-width: 96px;
    margin: 56px 104px 24px 104px ;
    height: auto;
    border-radius: 30px;
    display: block;
}
.socialContent a {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 50px;
    background-color: #ecefeb;
    transition: 0.3s;
    text-decoration: none;
    color: inherit;
}
.socialContent a:hover {
    background-color: #87ea5c;
}
.socialTitle {
    font-size: 30px;
    font-weight: 600;
}
.socialBtn {
    font-size: 30px;
    font-weight: 700;
    margin-top: 56px;
    margin-bottom: 64px;
}
@media screen and (max-width: 992px) {
    .socialContent .socialLogo {
        margin: 48px 56px 16px 56px ;
        border-radius: 20px;
    }
    .socialContent a {
        border-radius: 30px;
    }
    .socialTitle {
        font-size: 20px;
    }
    .socialBtn {
        font-size: 24px;
        margin-top: 28px;
        margin-bottom: 40px;
    }
}
@media screen and (max-width: 490px) {
    .socialContent .socialLogo {
        margin: 24px 48px 8px 48px ;
    }
    .socialTitle {
        font-size: 18px;
    }
    .socialBtn {
        font-size: 18px;
        margin-top: 24px;
        margin-bottom: 32px;
    }
}
</style>