<template>
    <TitleSection />
    <Solutions class="businessBG" />
    <LiffyTitle />
    <ItemSlide class="businessGap" />
    <BrandCol class="businessGap" />
    <SocialSlide class="businessGap"/>
    <Engaging class="businessGap" />
    <TrustedBy />
    <GetInTouch />
</template>

<script>
import BrandCol from '../business/BrandCol.vue';
import Engaging from '../business/Engaging.vue';
import ItemSlide from '../business/ItemSlide.vue';
import LiffyTitle from '../business/LiffyTitle.vue';
import SocialSlide from '../business/SocialSlide.vue';
import Solutions from '../business/Solutions.vue';
import TrustedBy from '../business/TrustedBy.vue';
import GetInTouch from '../common/GetInTouch.vue';
import TitleSection from '../common/TitleSection.vue';

export default {
  name : 'business',
  data(){
    return{
      
    }
  },
  components: {
    TitleSection,
    Solutions,
    LiffyTitle,
    ItemSlide,
    BrandCol,
    SocialSlide,
    Engaging,
    TrustedBy,
    GetInTouch
  }
}
</script>

<style>
.businessBG {
  background-image: url("../../assets/image/pattern/pattern1.png");
  padding-top: 220px;
  padding-bottom: 150px;
}
.businessGap {
  margin-top: 150px;
}
@media screen and (max-width: 490px) {
 .businessBG {
    padding-top: 105px;
    padding-bottom: 80px;
  }
  .businessGap {
    margin-top: 80px;
  }
}

</style>