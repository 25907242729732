<template>
  <div>
    <div class="d-flex w-100 flex-column">
      <div class="container ab_liffy_container">
        <main>
          <h1 class="liffyTitle">{{ $t('about.storySection.title') }}</h1>
          <div class="liffyDesc">
            <span>{{ $t('about.storySection.description.paragraphA.senA') }}</span>
            <span>{{ $t('about.storySection.description.paragraphA.senB') }}</span>
            <span class="liffyDesc_B">{{ $t('about.storySection.description.paragraphA.senC') }}</span>
          </div>

          <div class="liffyDesc">
            <span>{{ $t('about.storySection.description.paragraphB.senA') }}</span>
            <span>{{ $t('about.storySection.description.paragraphB.senB') }}</span>
          </div>

          <div class="liffyDesc">
            <span>{{ $t('about.storySection.description.paragraphC.senA') }}</span>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LiffyStory',
  data(){
    return{
      
    }
  }
}
</script>

<style>
.ab_liffy_container {
  justify-content: center;
}
.liffyTitle {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 70px;
}
.liffyDesc {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}
.liffyDesc_B {
  font-weight: 800 !important;
}
@media screen and (max-width: 490px) {
  .liffyTitle {
    font-size: 20px;
  }
  .liffyDesc {
    font-size: 14px;
    margin-bottom: 16px;
  }
}
</style>