<template>
<!-- 이미지의 비율을 살리기 위해서라도 새로 제작? -->
    <div class="bs-col-card-div">
        <img :src="`${CCimage}`" class="bs-col-card-img" />

        <div class="bs-col-card-context">
            <div class="bs-col-card-area">
                <div class="bs-col-card-logo" :style="{ backgroundImage: `url(${CClogo})` }"></div>
                <div class="bs-col-card-info">
                    <div class="bs-col-card-brand">{{CCtitle}}</div>
                    <div class="bs-col-card-brandDesc">{{CCsubtitle}}</div>
                </div>
            </div>
            <div class="bs-col-card-desc">{{CCdescription}}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CollaborationCard',
    data(){
        return{
            
        }
    },
    props: {
        CCtitle: {
            type: String,
            require: true
        },
        CCsubtitle: {
            type: String,
            require: true
        },
        CCdescription_code: {
            type: String,
            require: true
        },
        CClogo: {
            type: String,
            require: true
        },
        CCimage: {
            type: String,
            require: true
        }
    },
    computed: {
        CCdescription() {
            return this.$t(this.CCdescription_code);  // 키를 사용하여 번역된 문자열 반환
        }
    }
}
</script>

<style>
.bs-col-card-div {
    border-radius: 50px;
    background-color: white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
}
.bs-col-card-img {
    width: 100%;
    height: auto;
    border-radius: 50px 50px 0px 0px;
}
.bs-col-card-context {
    padding: 24px 24px 24px 24px;
    /* 규격에 따른 높이 지정이 필요하다. */
}
.bs-col-card-area {
    margin-bottom: 32px;
    text-align: left;
}
.bs-col-card-logo {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    border: thin solid rgba(0, 0, 0, 0.15);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: inline-block;
    vertical-align: middle;
}
.bs-col-card-info {
    margin-left: 16px;
    display: inline-block;
    vertical-align: middle;
}
.bs-col-card-brand {
    font-size: 30px;
    font-weight: 600;
}
.bs-col-card-brandDesc {
    font-size: 22px;
    font-weight: 500;
}
.bs-col-card-desc {
    font-size: 20px;
    font-family: 500;
    line-height: 165%;
    text-align: left;
}
@media screen and (max-width: 1400px) {

}
@media screen and (max-width: 1200px) {

}
@media screen and (max-width: 992px) {
    .bs-col-card-div {
        border-radius: 20px;
    }
    .bs-col-card-img {
        border-radius: 20px 20px 0px 0px;
    }
    .bs-col-card-context {
        padding: 16px 16px 20px 16px;
    }
    .bs-col-card-area {
        margin-bottom: 16px;
    }
    .bs-col-card-logo {
        width: 72px;
        height: 72px;
    }
    .bs-col-card-info {
        margin-left: 12px;
    }
    .bs-col-card-brand {
        font-size: 20px;
    }
    .bs-col-card-brandDesc {
        font-size: 16px;
    }
    .bs-col-card-desc {
        font-size: 14px;
        font-size: 15px;
    }
}
@media screen and (max-width: 768px) {
    .bs-col-card-context {
        padding: 12px 12px 20px 12px;
    }
    .bs-col-card-area {
        margin-bottom: 12px;
    }
    .bs-col-card-logo {
        width: 40px;
        height: 40px;
    }
    .bs-col-card-info {
        margin-left: 8px;
    }
    .bs-col-card-brand {
        font-size: 14px;
    }
    .bs-col-card-brandDesc {
        font-size: 14px;
    }
    .bs-col-card-desc {
        font-size: 12px;
        font-size: 13px;
    }
}
@media screen and (max-width: 490px) {
    .bs-col-card-context {
        padding: 8px 8px 16px 8px;
        /* 규격에 따른 높이 지정이 필요하다. */
    }
    .bs-col-card-area {
        margin-bottom: 8px;
    }
    .bs-col-card-logo {
        width: 36px;
        height: 36px;
    }
    .bs-col-card-info {
        margin-left: 4px;
    }
    .bs-col-card-brand {
        font-size: 12px;
    }
    .bs-col-card-brandDesc {
        font-size: 12px;
    }
    .bs-col-card-desc {
        font-size: 11px;
        line-height: 12px;
    }
}
</style>