<template>
  <div class="footer_Section">
    <div class="f_RestrictWidth">
      <div class="footer_top">
        <div>
          <img :src="logoSVG" alt="LIFFY" class="footer_logo"/>
        </div>

        <div class="footer_social">
          <a v-for="(a, i) in FooterSocial" :key="i" :href="a.hyperlink" class="social_icon" target="_blank">
            <img :src="a.image" :alt="a.name">
          </a>
        </div>
      </div>

      <div class="footer_Border"></div>

      <div class="footer_bottom">
        <div>&copy; 2024 Liffy. All rights reserved.</div>

        <div class="footer_privacy">
          <ul class="navbar-nav flex-grow-1 align-items-center" style="flex-direction: row">
            <li>
              <router-link to="/policy" class="active footer_link">Privacy Policy</router-link>
            </li>
            <li class="footer_Gap">
              <div class='v_footer_line'></div>
            </li>
            <li>
              <a href="https://pondstudio.tv" target="_blank" class="active lang footer_link" aria-current="page">Pond Studio</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Footer',
  data(){
    return{
      FooterSocial: [
        {
        id: 1,
        name: 'X',
        hyperlink: 'https://x.com/lily_liffy',
        image: require('../../assets/icon/x.svg')
        },
        {
        id: 2,
        name: 'TikTok',
        hyperlink: 'https://www.tiktok.com/@lily_liffy',
        image: require('../../assets/icon/tiktok.svg')
        },
        {
        id: 3,
        name: 'Youtube',
        hyperlink: 'https://www.youtube.com/@lily_liffy',
        image: require('../../assets/icon/youtube.svg')
        },
        {
        id: 4,
        name: 'Discord',
        hyperlink: 'https://discord.gg/vBeJhes2nX',
        image: require('../../assets/icon/discord.svg')
        }
      ],
      logoSVG: require('../../assets/image/logo/logo.svg'),
    }
  }
}
</script>

<style>
.footer_Section {
  padding-top: 136px;
  padding-bottom: 56px;
}

.footer_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.footer_logo {
  width: 180px;
}

.footer_social {
  display: flex;
  gap: 8px;
}

.social_icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}

.footer_Border {
  border-bottom: 1px solid #a6a6a6 !important;
}

.footer_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a6a6a6 !important;
  font-size: 12px;
  font-weight: 500;
  margin-top: 16px;
}

.footer_Gap {
  margin-left: 24px;
  margin-right: 24px;
}

.footer_link {
  color: #a6a6a6;
  display: block;
  text-decoration: none;
  cursor: pointer;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.v_footer_line {
  border-left : thin solid #a6a6a6;
  height : 12px;
}

.f_RestrictWidth {
  margin: auto;
  width: 66%;
}

@media screen and (max-width: 1200px) {
  .footer_Section {
    padding-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .f_RestrictWidth{
    /* 비율로 수정하는게 좋을지도 */
    width: 90% !important;
  }
}
@media screen and (max-width: 490px) {
  .footer_Section {
    padding-top: 40px;
    padding-bottom: 48px;
  }
  .footer_logo {
    width: 80px;
  }
  .social_icon {
    width: 24px;
    height: 24px;
  }
  .footer_top {
    flex-direction: column;
  }
  .footer_social {
    margin-top: 16px;
  }
  .footer_bottom {
    flex-direction: column;
  }
  .footer_privacy {
    margin-top: 8px;
  }
}

</style>