<template>
  <header class="headerCSS">
        <nav class="navbar navbar-expand-md navbar-dark bg-white fixed-top pdCl " id="navbar">
            <div class="container-fluid restrictWidth">

                <router-link to="/" class="navbar-brand navList">
                <img src="../../assets/icon/symbol.svg" width="40" height="40" class="me-3 navIcon" alt="Bootstrap">
                </router-link>

                <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbarExample-expand-lg" aria-controls="offcanvasNavbarExample-expand-lg">
                    <!-- <span class="nav_Hamburger" data-bs-target="#offcanvasNavbarExample-expand-lg"></span> -->
                    <span class="nav_Hamburger"></span>
                </button>
                
                <div class="offcanvas offcanvas-end bg-white " data-bs-hideresize="true" tabindex="-1" id="offcanvasNavbarExample-expand-lg" aria-labelledby="offcanvasNavbarExample-expand-lg">
                    <div class="offcanvas-header navHeight">
                        <a class="navbar-brand navList" href="#"><img src="../../assets/icon/symbol.svg" width="40" height="40" class="me-3" alt="Bootstrap"></a>
                        <button type="button" class="btn-close-dark text-reset nav_Close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body bg-white">
                        <ul class="navbar-nav justify-content-end flex-grow-1">
                            <li v-for="(a,i) in NavigationList" :key="i" class="nav-item underline" :class="{'active-underline': isActive(a.title)}">
                                <router-link :to="a.title" class="nav-link navList navList_text" :class="{'active-bold': isActive(a.title)}" :style="{'width': a.width+'px'}" @click="closeModal()">{{a.title}}</router-link>
                                <!-- <a :href="a.title" class="nav-link navList navList_text" :class="{'active-bold': isActive(a.title)}" :style="{'width': a.width+'px'}">{{a.title}}</a> -->
                            </li>
                        </ul>
                        
                        <ul class="navbar-nav languageSelection flex-grow-1 align-items-center" style="flex-direction: row">
                            <li class="nav-item">
                                <a class="nav-link navList" aria-current="page" @click="changeLanguage()">
                                    <div class="nav_language_a">{{currentLanguage}}</div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
export default {
name: 'TopNavigation',
    data(){
        return{
            NavigationList: [
                {
                id: 1,
                title: 'about',
                width: 74.2
                },
                {
                id: 2,
                title: 'business',
                width: 99.4
                },
                {
                id: 3,
                title: 'contact',
                width: 90.35
                }
            ],
            currentLanguage: "KR"
        }
    },
    methods: {
        changeLanguage() {
            if(this.$i18n.locale == "kr") {
                this.currentLanguage = this.$i18n.locale.toUpperCase();
                this.$i18n.locale = "en";
            } else if(this.$i18n.locale == "en") {
                this.currentLanguage = this.$i18n.locale.toUpperCase();
                this.$i18n.locale = "kr";
            }
        },
        isActive(title) {
            return this.$route.path === `/${title}`;
        },
        closeModal() {
            const offcanvasElement = document.getElementById('offcanvasNavbarExample-expand-lg');
            const offcanvas = window.bootstrap.Offcanvas.getInstance(offcanvasElement);
            if (offcanvas) {
                offcanvas.hide();
            }
        }
    }
}
</script>

<style>


.navList {
    align-content: center;
    height: 70px;
    color: #083400 !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    text-transform: capitalize;
}
.nav_Hamburger {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-image: url('../../assets/icon/hamburger.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}
.offcanvas-header {
    justify-content: space-between;
}
.nav_Close {
    border: 0;
    background-color: white;
    width: 1.5em;
    height: 1.5em;
    background-image: url('../../assets/icon/exit.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}
.navIcon {
    filter: invert(14%) sepia(25%) saturate(3171%) hue-rotate(74deg) brightness(99%) contrast(102%);
    transition: filter 0.3s;
}

.navIcon:hover {
    filter: invert(22%) sepia(54%) saturate(1720%) hue-rotate(75deg) brightness(91%) contrast(104%);
}

.v-line {
    border-left : thin solid #a6a6a6;
    height : 10px;
}
.nav_language_a {
    width: 36px;
    height: 24px;
    border-radius: 5px;
    font-size: 15px !important;
    font-weight: 800;
    align-content: center;
    color: #fff;
    background-color: #083400;
    transition: background-color 0.3s;
}
.nav_language_a:hover {
    background-color: #0c5000;
}

.navHeight {
    height: 70px;
}
.headerCSS {
    position: relative;
    z-index: 10000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.underline:not(:last-child) { 
    margin-right: 40px;
}
@media screen and (min-width: 768px) {
    .restrictWidth{
        width: 66% !important;
    }

    .languageSelection{
        justify-content: flex-end !important;
    }
}

@media screen and (max-width: 767px) {
    .restrictWidth{
        width: 90% !important;
    }
    .navList_text {
        width: unset !important;
    }
    .languageSelection{
        justify-content: center !important;
    }

    .languageSelection li {
        padding: 0px 14px 0px 14px;
    }

    .underline { 
        display: inline-block; 
        border-bottom: solid 1px #888888;  
    }
    .underline:not(:last-child) { 
        margin-right: unset;
    }
}
.pdCl {
    padding: 0 !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
}
.underline:after {
    display:block;
    content: '';
    border-bottom: solid 5px #083400;  
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
}
.active-bold {
    font-weight: 900 !important;
}
.underline:hover:after { transform: scaleX(1); }
@media screen and (min-width: 768px) {
    .active-underline::after {
    transform: scaleX(1) !important;
}
}

</style>