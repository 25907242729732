<template>
  <div>
    <div class="d-flex w-100 flex-column">
      <div class="container bs_social_title">
        <div class="bs_social_title_area">
          <sectionTitle :msg="$t('business.socialSection.title')" :margin="30" />
        </div>
        <div>
          <p class="bs_is_desc">{{ $t('business.socialSection.description') }}</p>
        </div>
      </div>
    </div>

    <div>
      <ul class="bs_social_CardList">
        <li v-for="(a, i) in SocialCardList" :key="i" class="bs_social_item">
          <SocialCard :socialTitle="a.title" :socialLogo="a.logo" :socialLink="a.link" />
        </li>
      </ul>
    </div>

    <div class="d-flex w-100 flex-column">
      <div class="container bs_social_btn">
        <button @click="handleClick('socialPrev')" class="btn-circle" :class="{'bs_social_btnDisabled' : socialPrevDisabled}" type="button">
          <img src="../../assets/icon/arrowL.svg" class="btn-icon" :class="{'bs_social_iconDisabled' : socialPrevDisabled}" alt="Prev" />
        </button>
        <button @click="handleClick('socialNext')" class="btn-circle btn-next" :class="{'bs_social_btnDisabled' : socialNextDisabled}" type="button">
          <img src="../../assets/icon/arrowR.svg" class="btn-icon" :class="{'bs_social_iconDisabled' : socialNextDisabled}"  alt="Next" />
        </button>
      </div>
    </div>
  </div>


  

  <!-- <button type="button" class="btn btn-primary" @click="more">더보기</button> -->
</template>

<script>
import sectionTitle from '../common/sectionTitle.vue';
import SocialCard from './SocialCard.vue';

export default {
  name: 'SocialSlide',
  data(){
    return{
      SocialCardList: [
        {
          id: 1,
          title: 'ROBLOX GROUP',
          logo: require('../../assets/image/socialLogo/social_roblox.jpg'),
          link: 'https://bit.ly/3O4r28U'
        },
        {
          id: 2,
          title: 'X',
          logo: require('../../assets/image/socialLogo/social_x.jpg'),
          link: 'https://x.com/lily_liffy'
        },
        {
          id: 3,
          title: 'DISCORD',
          logo: require('../../assets/image/socialLogo/social_discord.jpg'),
          link: 'https://discord.gg/vBeJhes2nX'
        },
        {
          id: 4,
          title: 'YOUTUBE',
          logo: require('../../assets/image/socialLogo/social_youtube.jpg'),
          link: 'https://www.youtube.com/@lily_liffy'
        }
      ],
      socialPrevDisabled: true,
      socialNextDisabled: false,
      socialScreenCnt: 0
    }
  },
  components: {
    sectionTitle,
    SocialCard
  },
  methods: {
    handleClick(direction) {
      const bs_social_CardList = document.querySelector(".bs_social_CardList")
      const bs_social_item = document.querySelector(".bs_social_item");
      const socialItemWidth = bs_social_item.offsetWidth;
      if(direction === "socialPrev" && !this.socialPrevDisabled) {
        bs_social_CardList.scrollBy({ left: -socialItemWidth, behavior: "smooth" });
      } else if(direction === "socialNext" && !this.socialNextDisabled) {
        bs_social_CardList.scrollBy({ left: socialItemWidth, behavior: "smooth" });
      }
    }
  },
  mounted() {
    const bs_social_CardList = document.querySelector(".bs_social_CardList")
    const bs_social_item = document.querySelector(".bs_social_item");
    bs_social_CardList.addEventListener('scroll', () => {
      this.socialScreenCnt = (window.innerWidth > 1400 && 0.1) || (window.innerWidth > 992 && 1) || 2;
      this.socialPrevDisabled = bs_social_CardList.scrollLeft === 0;
      this.socialNextDisabled = bs_social_CardList.scrollLeft >= (bs_social_item.offsetWidth + 48) * this.socialScreenCnt;
    })
  }
}

</script>

<style>
.bs_social_title_area {
  display: flex;
  justify-content: space-between;
}
.bs_social_title {
  flex-direction: column;
  text-align: left;
}
.bs_social_item {
  width: 40%;
  max-width: 400px;
  min-width: 200px;
  flex-shrink: 0;
  border-radius: 50px;
  scroll-snap-align: start;
}
.bs_social_CardList {
  display: flex;
  gap: 48px;
  padding: 0px calc((100% - 1320px)/2);
  scroll-padding: 0px calc((100% - 1320px)/2); 
  align-items: center;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: hidden;
  margin-bottom: 48px;
}
.bs_social_CardList::-webkit-scrollbar {
  display: none;
}
.bs_social_btn {
  justify-content: right;
}
.bs_social_btnDisabled {
  background-color: #ecefeb !important;
  cursor: not-allowed;
}
.bs_social_iconDisabled {
  filter: invert(78%) sepia(4%) saturate(231%) hue-rotate(62deg) brightness(90%) contrast(87%);
}

@media screen and (max-width: 1400px) {
  .bs_social_CardList {
    padding: 0px calc((100% - 1140px)/2);
    scroll-padding: 0px calc((100% - 1140px)/2); 
  }
}
@media screen and (max-width: 1200px) {
  .bs_social_CardList {
    padding: 0px calc((100% - 960px)/2);
    scroll-padding: 0px calc((100% - 960px)/2); 
  }
}
@media screen and (max-width: 992px) {
  .bs_social_CardList {
    gap: 24px;
    padding: 0px calc((100% - 720px)/2);
    scroll-padding: 0px calc((100% - 720px)/2); 
  }
}
@media screen and (max-width: 768px) {
  .bs_social_CardList {
    padding: 0px calc((100% - 540px)/2);
    scroll-padding: 0px calc((100% - 540px)/2); 
  }
}
@media screen and (max-width: 490px) {
  .bs_social_CardList {
    gap: 16px;
    padding: 0px 20px;
    scroll-padding: 0px 20px;
    margin-bottom: 8px;
  }
}
</style>