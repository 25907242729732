<template>
  <div>
    <div class="d-flex w-100 flex-column policy_bg">
      <div class="container policy_container">

        <div><sectionTitle :iconBlack="true" :msg="$t('policy.title')" /></div>


        <!-- 여기서 보안 취약성 발견 -->
        <div class="policy_article policy_ar_intro">
          <p>{{ $t('policy.policy00_intro.desc') }}</p>
        </div>

        <div class="policy_article policy_ar1">
          <p>{{ $t('policy.policy_ar1.title') }}</p>
          <p>{{ $t('policy.policy_ar1.desc') }}</p>
          <ul>
            <li>{{ $t('policy.policy_ar1.ul.ul_1.desc') }}</li>
            <li>{{ $t('policy.policy_ar1.ul.ul_2.desc') }}</li>
            <li>{{ $t('policy.policy_ar1.ul.ul_3.desc') }}</li>
          </ul>
        </div>

        <div class="policy_article policy_ar2">
          <p>{{ $t('policy.policy_ar2.title') }}</p>
          <p>{{ $t('policy.policy_ar2.desc') }}</p>
          <ul>
            <li><dl>
              <dt>{{ $t('policy.policy_ar2.ul.ul_1.desc') }}</dt>
              <dd>{{ $t('policy.policy_ar2.ul.ul_1.details.detail_1.title') }}</dd>
              <dd>{{ $t('policy.policy_ar2.ul.ul_1.details.detail_2.title') }}</dd>
            </dl></li>
            
            <li><dl>
              <dt>{{ $t('policy.policy_ar2.ul.ul_2.desc') }}</dt>
              <dd>{{ $t('policy.policy_ar2.ul.ul_2.details.detail_1.title') }}</dd>
            </dl></li>

            <li><dl>
              <dt>{{ $t('policy.policy_ar2.ul.ul_3.desc') }}</dt>
              <dd>{{ $t('policy.policy_ar2.ul.ul_3.details.detail_1.title') }}</dd>
            </dl></li>

            <li><dl>
              <dt>{{ $t('policy.policy_ar2.ul.ul_4.desc') }}</dt>
              <dd>{{ $t('policy.policy_ar2.ul.ul_4.details.detail_1.title') }}</dd>
            </dl></li>
          </ul>
        </div>

        <div class="policy_article policy_ar3">
          <p>{{ $t('policy.policy_ar3.title') }}</p>
          <p>{{ $t('policy.policy_ar3.desc') }}</p>
          <ul>
            <li><dl>
              <dt>{{ $t('policy.policy_ar3.ul.ul_1.desc') }}</dt>
              <dd>{{ $t('policy.policy_ar3.ul.ul_1.details.detail_1.title') }}</dd>
              <dd>{{ $t('policy.policy_ar3.ul.ul_1.details.detail_2.title') }}</dd>
            </dl></li>
            
            <li><dl>
              <dt>{{ $t('policy.policy_ar3.ul.ul_2.desc') }}</dt>
              <dd>{{ $t('policy.policy_ar3.ul.ul_2.details.detail_1.title') }}</dd>
              <dd>{{ $t('policy.policy_ar3.ul.ul_2.details.detail_2.title') }}</dd>
            </dl></li>

            <li><dl>
              <dt>{{ $t('policy.policy_ar3.ul.ul_3.desc') }}</dt>
              <dd>{{ $t('policy.policy_ar3.ul.ul_3.details.detail_1.title') }}</dd>
              <dd>{{ $t('policy.policy_ar3.ul.ul_3.details.detail_2.title') }}</dd> 
            </dl></li>
          </ul>
        </div>

        <div class="policy_article policy_ar4">
          <p>{{ $t('policy.policy_ar4.title') }}</p>
          <p>{{ $t('policy.policy_ar4.desc') }}</p>
          <ol>
            <li>{{ $t('policy.policy_ar4.ul.ul_1.desc') }}</li>
            <li>{{ $t('policy.policy_ar4.ul.ul_2.desc') }}</li>
            <li>{{ $t('policy.policy_ar4.ul.ul_3.desc') }}</li>
            <li>{{ $t('policy.policy_ar4.ul.ul_4.desc') }}</li>
          </ol>
        </div>

        <div class="policy_article policy_ar5">
          <p>{{ $t('policy.policy_ar5.title') }}</p>
          <ul>
            <li><dl>
              <dt>{{ $t('policy.policy_ar5.ul.ul_1.desc') }}</dt>
              <dd>{{ $t('policy.policy_ar5.ul.ul_1.details.detail_1.title') }}</dd>
              <dd>{{ $t('policy.policy_ar5.ul.ul_1.details.detail_2.title') }}</dd>
              <dd>{{ $t('policy.policy_ar5.ul.ul_1.details.detail_3.title') }}</dd>
              <dd>{{ $t('policy.policy_ar5.ul.ul_1.details.detail_4.title') }}</dd>
            </dl></li>
            
            <li><dl>
              <dt>{{ $t('policy.policy_ar5.ul.ul_2.desc') }}</dt>
            </dl></li>

            <li><dl>
              <dt>{{ $t('policy.policy_ar5.ul.ul_3.desc') }}</dt>
            </dl></li>
            
            <li><dl>
              <dt>{{ $t('policy.policy_ar5.ul.ul_4.desc') }}</dt>
            </dl></li>

            <li><dl>
              <dt>{{ $t('policy.policy_ar5.ul.ul_5.desc') }}</dt>
            </dl></li>
          </ul>
        </div>

        <div class="policy_article policy_ar6">
          <p>{{ $t('policy.policy_ar6.title') }}</p>

          <ul>
            <li><dl>
              <dt>{{ $t('policy.policy_ar6.ul.ul_1.desc') }}</dt>
            </dl></li>
            
            <li><dl>
              <dt>{{ $t('policy.policy_ar6.ul.ul_2.desc') }}</dt>
            </dl></li>

            <li><dl>
              <dt>{{ $t('policy.policy_ar6.ul.ul_3.desc') }}</dt>
            </dl></li>
            
            <li><dl>
              <dt>{{ $t('policy.policy_ar6.ul.ul_4.desc') }}</dt>
              <dd>{{ $t('policy.policy_ar6.ul.ul_4.details.detail_1.title') }}</dd>
              <dd class="policy_ar6_detailDesc">{{ $t('policy.policy_ar6.ul.ul_4.details.detail_1.desc') }}</dd>
              <dd>{{ $t('policy.policy_ar6.ul.ul_4.details.detail_2.title') }}</dd>
              <dd class="policy_ar6_detailDesc">{{ $t('policy.policy_ar6.ul.ul_4.details.detail_2.desc') }}</dd>
            </dl></li>
          </ul>
        </div>

        <div class="policy_article policy_ar7">
          <p>{{ $t('policy.policy_ar7.title') }}</p>

          <ul>
            <li><dl>
              <dt>{{ $t('policy.policy_ar7.ul.ul_1.desc') }}</dt>
            </dl></li>
            
            <li><dl>
              <dt>{{ $t('policy.policy_ar7.ul.ul_2.desc') }}</dt>
              <dd>{{ $t('policy.policy_ar7.ul.ul_2.details.detail_1.title') }}</dd>
              <dd>{{ $t('policy.policy_ar7.ul.ul_2.details.detail_2.title') }}</dd>
              <dd>{{ $t('policy.policy_ar7.ul.ul_2.details.detail_3.title') }}</dd>
              <dd>
                <ul class="policy_ar7_cookie">
                  <li>{{ $t('policy.policy_ar7.ul.ul_2.details.detail_3.linkA') }} <a href="https://support.microsoft.com/microsoft-edge/view-cookies-in-microsoft-edge-a7d95376-f2cd-8e4a-25dc-1de753474879" target="_blank">{{ $t('policy.policy_ar7.ul.ul_2.details.detail_3.confirm') }}</a></li>
                  <li>{{ $t('policy.policy_ar7.ul.ul_2.details.detail_3.linkB') }} <a href="https://support.apple.com/guide/safari/sfri11471/mac" target="_blank">{{ $t('policy.policy_ar7.ul.ul_2.details.detail_3.confirm') }}</a></li>
                  <li>{{ $t('policy.policy_ar7.ul.ul_2.details.detail_3.linkC') }} <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank">{{ $t('policy.policy_ar7.ul.ul_2.details.detail_3.confirm') }}</a></li>
                  <li>{{ $t('policy.policy_ar7.ul.ul_2.details.detail_3.linkD') }} <a href="https://support.google.com/chrome/bin/answer.py?hl=en&amp;answer=95647" target="_blank">{{ $t('policy.policy_ar7.ul.ul_2.details.detail_3.confirm') }}</a></li>
                </ul>
              </dd>
            </dl></li>
          </ul>
        </div>


        <div class="policy_article policy_ar8">
          <p>{{ $t('policy.policy_ar8.title') }}</p>
          <ul>
            <li><dl>
              <dt>{{ $t('policy.policy_ar8.ul.ul_1.desc') }}</dt>
              <dd>{{ $t('policy.policy_ar8.ul.ul_1.details.detail_1.title') }}</dd>
              <dd>{{ $t('policy.policy_ar8.ul.ul_1.details.detail_2.title') }}</dd>
              <dd>{{ $t('policy.policy_ar8.ul.ul_1.details.detail_3.title') }}</dd>
              <dd>{{ $t('policy.policy_ar8.ul.ul_1.details.detail_4.title') }}</dd>
            </dl></li>
          </ul>
          <p>{{ $t('policy.policy_ar8.desc') }}</p>
        </div>

        <div class="policy_article policy_ar9">
          <p>{{ $t('policy.policy_ar9.title') }}</p>
          <p>{{ $t('policy.policy_ar9.desc') }}</p>
          <ul>
            <li><dl>
              <dd>{{ $t('policy.policy_ar9.ul.ul_1.desc') }}</dd>
              <dd>{{ $t('policy.policy_ar9.ul.ul_2.desc') }}</dd>
              <dd>{{ $t('policy.policy_ar9.ul.ul_3.desc') }}</dd>
              <dd>{{ $t('policy.policy_ar9.ul.ul_4.desc') }}</dd>
            </dl></li>
          </ul>
        </div>

        <div class="policy_article policy_ar10">
          <p>{{ $t('policy.policy_ar10.title') }}</p>
          <p>{{ $t('policy.policy_ar10.desc') }}</p>
          <ul>
            <li>{{ $t('policy.policy_ar10.ul.ul_1.desc') }}</li>
            <li>{{ $t('policy.policy_ar10.ul.ul_2.desc') }}</li>
          </ul>
        </div>

        <div class="policy_article policy_ar11">
          <p>{{ $t('policy.policy_ar11.title') }}</p>
          <p>{{ $t('policy.policy_ar11.desc') }}</p>
          <ul>
            <li><dl>
              <dt>{{ $t('policy.policy_ar11.ul.ul_1.desc') }}</dt>
              <dd>{{ $t('policy.policy_ar11.ul.ul_1.details.detail_1.title') }}</dd>
              <dd>{{ $t('policy.policy_ar11.ul.ul_1.details.detail_2.title') }} <a href="https://privacy.kisa.or.kr/main.do" target="_blank">privacy.kisa.or.kr</a></dd>
              <dd>{{ $t('policy.policy_ar11.ul.ul_1.details.detail_3.title') }}</dd>
              <dd>{{ $t('policy.policy_ar11.ul.ul_1.details.detail_4.title') }}</dd>
            </dl></li>
          </ul>

          <ul>
            <li><dl>
              <dt>{{ $t('policy.policy_ar11.ul.ul_2.desc') }}</dt>
              <dd>{{ $t('policy.policy_ar11.ul.ul_2.details.detail_1.title') }}</dd>
              <dd>{{ $t('policy.policy_ar11.ul.ul_2.details.detail_2.title') }} <a href="https://www.kopico.go.kr/main/main.do" target="_blank">www.kopico.go.kr</a></dd>
              <dd>{{ $t('policy.policy_ar11.ul.ul_2.details.detail_3.title') }}</dd>
              <dd>{{ $t('policy.policy_ar11.ul.ul_2.details.detail_4.title') }}</dd>
            </dl></li>
          </ul>

          <ul>
            <li><dl>
              <dt>{{ $t('policy.policy_ar11.ul.ul_3.desc') }}</dt>
              <dd>{{ $t('policy.policy_ar11.ul.ul_3.details.detail_1.title') }} <a href="https://www.spo.go.kr/site/spo/main.do" target="_blank">www.spo.go.kr</a></dd>
              <dd>{{ $t('policy.policy_ar11.ul.ul_3.details.detail_2.title') }}</dd>
            </dl></li>
          </ul>

          <ul>
            <li><dl>
              <dt>{{ $t('policy.policy_ar11.ul.ul_4.desc') }}</dt>
              <dd>{{ $t('policy.policy_ar11.ul.ul_4.details.detail_1.title') }} <a href="https://cyberbureau.police.go.kr/prevention/sub4_4.jsp?mid=020505" target="_blank">cyberbureau.police.go.kr</a></dd>
              <dd>{{ $t('policy.policy_ar11.ul.ul_4.details.detail_2.title') }}</dd>
            </dl></li>
          </ul>
        </div>

        <div class="policy_article policy_ar12">
          <p>{{ $t('policy.policy_ar12.title') }}</p>
          <ul>
            <li>{{ $t('policy.policy_ar12.ul.ul_1.desc') }}</li>
          </ul>
        </div>
        
      </div>
    </div>
  </div>
  
</template>

<script>
import sectionTitle from '../common/sectionTitle.vue'
export default {
  name : 'policy',
  components : {
    sectionTitle
  }
}
</script>

<style>
.policy_bg {
  text-align: left;
  background-color: #87ea5c;
  background-image: url("../../assets/image/pattern/pattern1.png");
}
.policy_container {
  margin-top: 144px;
  margin-bottom: 220px;
  flex-direction: column;
  padding: 0;
}
.policy_article {
  font-size: 20px;
  font-weight: 500;
  margin-top: 40px;
}
.policy_article p:first-child {
  font-weight: 700;
}
.policy_article p {
  margin-bottom: 16px;
}
.policy_article ul {
  display: block;
  margin-bottom: 0px;
}
.policy_article li {
  display: block;
}
.policy_article dl {
  margin-bottom: 0px;
}
.policy_article dt {
  font-size: 20px;
  font-weight: 500;
}
.policy_article dd {
  padding-left: 32px;
  margin-bottom: 0px;
}
.policy_article a{
  color: inherit;
}
.policy_article ul li:not(:last-child) {
  margin-bottom: 16px;
}
.policy_ar4 ol li:not(:last-child) {
  margin-bottom: 16px;
}
.policy_ar6_detailDesc {
  text-indent: 16px;
}
.policy_ar7_cookie li {
  margin-bottom: 0px !important;
}
.policy_ar8 dt, .policy_ar8 dl {
  margin-bottom: 16px;
}
.policy_ar10 li, .policy_ar10 p:not(:first-child) {
  margin-bottom: 0px !important;
}
.policy_ar11 p:not(:first-child) {
  margin-bottom: 0px;
}
.policy_ar11 ul:not(:last-child) {
  margin-bottom: 16px;
}
@media screen and (max-width: 490px) {
  .policy_bg {
    padding: 0px 20px;
  }
  .policy_container {
    margin-top: 48px;
    margin-bottom: 64px;
  }
  .policy_article {
    font-size: 11px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .policy_article p {
    margin-bottom: 8px;
  }
  .policy_article ul {
    padding-left: 1rem;
  }
  .policy_article ol {
    padding-left: 1rem;
  }
  .policy_article dt {
    font-size: 11px;
  }
  .policy_article dd {
    padding-left: 1rem;
  }
  .policy_article ul li:not(:last-child) {
    margin-bottom: 8px;
  }
  .policy_ar4 ol li:not(:last-child) {
    margin-bottom: 8px;
  }
  .policy_ar8 dt, .policy_ar8 dl {
    margin-bottom: 8px;
  }
  .policy_ar11 ul:not(:last-child) {
    margin-bottom: 8px;
  }

}
</style>