<template>
    <div class="scContent">
        <a :href="link" target="_blank">
            <img :src="`${bgImg}`" class="scBG"/>
        </a>
        <div class="scDiv">
            <p class="scCategory">{{category}}</p>
            <p class="scTitle">
                {{os_title}}
                <img :src="arrowSVG" alt="Section Title"  class="scIcon"/>
            </p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'serviceCard',
    data(){
        return{
            arrowSVG: require('../../assets/icon/arrowS.svg'),
        }
    },
    props: {
        os_titleCode: {
            type: String,
            require: true
        },
        category: {
            type: String,
            require: true
        },
        link: {
            type: String,
            require: true
        },
        bgImg: {
            type: String,
            require: true
        }
    },
    computed: {
        os_title() {
            return this.$t(this.os_titleCode);  // 키를 사용하여 번역된 문자열 반환
        }
    }
}
</script>

<style>
.scContent {
    position: relative;
    display: inline-block;
}
.scContent .scBG {
    width: 100%;
    height: auto;
    border-radius: 50px;
    display: block;
}
.scContent a:after {
    content:"";
    position: absolute;
    width:100%;
    height:100%;
    border-radius: 50px;
    top:0;
    left:0;
    background: radial-gradient(rgba(0,0,0,0.4) 0%,rgba(0,0,0,0.6) 100%);
    opacity: 0.5;
    transition: all 0.3s ease;
}
.scContent a:hover:after{
    opacity: 1;
}
.scIcon {
    filter: invert(100%) sepia(1%) saturate(2%) hue-rotate(177deg) brightness(107%) contrast(101%);
    width: 24px;
    height: 24px;   
    margin-left: 8px;
}
.scDiv {
    position: absolute;
    bottom: 80px;
    left: 64px;
    pointer-events: none;
}
.scCategory {
    color: #9ce44a;
    font-size: 30px;
    text-align: left;
    margin-bottom: 0px;
}
.scTitle {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 0px;
}
@media screen and (max-width: 1200px) {
    .scIcon {
        width: 20px;
        height: 20px;   
        margin-left: 6px;
    }
    .scDiv {
        bottom: 64px;
        left: 48px;
    }
    .scCategory {
        font-size: 27px;
    }
    .scTitle {
        font-size: 36px;
    }
}
@media screen and (max-width: 992px) {
    .scIcon {
        width: 16px;
        height: 16px;   
        margin-left: 4px;
    }
    .scDiv {
        bottom: 48px;
        left: 40px;
    }
    .scCategory {
        font-size: 18px;
    }
    .scTitle {
        font-size: 24px;
    }
}
@media screen and (max-width: 768px) {
    .scContent .scBG {
        border-radius: 30px;
    }
    .scContent a:after {
        border-radius: 30px;
    }
    .scIcon {
        width: 12px;
        height: 12px;   
        margin-left: 4px;
    }
    .scDiv {
        bottom: 36px;
        left: 32px;
    }
    .scCategory {
        font-size: 15px;
    }
    .scTitle {
        font-size: 20px;
    }
}
@media screen and (max-width: 490px) {
    .scContent .scBG {
        border-radius: 20px;
    }
    .scContent a:after {
        border-radius: 20px;
    }
    .scIcon {
        width: 8px;
        height: 8px;   
        margin-left: 2px;
    }
    .scDiv {
        bottom: 24px;
        left: 24px;
    }
    .scCategory {
        font-size: 12px;
    }
    .scTitle {
        font-size: 15px;
    }
}
</style>