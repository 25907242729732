<template>
  <div>
    <div class="cover-container d-flex w-100 mx-auto flex-column">
      <p class="fp_title">Our footprints</p>
    </div>

    <div class="cover-container d-flex w-100 mx-auto flex-column">
      <div class="fp_outerDiv">
        <div class="fp_innerDiv">

          <ul class="fp_left">    <!-- 이게 왼쪽 라인 -->

            <li class="fp_year fp_year_left fp_year_left_jul2024">   <!-- 이게 Year의 한 세트 -->
              <div> <!-- 이게 Year의 Cover -->
                <ul>  <!-- 이게 Year -->
                  <li class="fp_month fp_month_left">  <!-- 이게 Month -->
                    JUL
                    <ul class="fp_contents">
                      <li class="fp_context">
                        <div class="fp_contextWidth fp_contextWidth_left">
                          <p class="fp_zeroSet">{{ $t('about.history.Jul2024.hisA') }}</p>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>

            <li class="fp_year fp_year_left fp_year_left_jun2024">   <!-- 이게 Year의 한 세트 -->
              <div> <!-- 이게 Year의 Cover -->
                <ul>  <!-- 이게 Year -->
                  <li class="fp_month fp_month_left">  <!-- 이게 Month -->
                    JUN
                    <ul class="fp_contents">
                      <li class="fp_context">
                        <div class="fp_contextWidth fp_contextWidth_left">
                          <p class="fp_zeroSet">{{ $t('about.history.Jun2024.hisA') }}</p>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
            
            <li class="fp_year fp_year_left fp_year_left_apr2024">   <!-- 이게 Year의 한 세트 -->
              <div> <!-- 이게 Year의 Cover -->
                <ul>  <!-- 이게 Year -->
                  <li class="fp_month fp_month_left">  <!-- 이게 Month -->
                    APR
                    <ul class="fp_contents">
                      <li class="fp_context">
                        <div class="fp_contextWidth fp_contextWidth_left">
                          <p class="fp_zeroSet">{{ $t('about.history.Apr2024.hisA') }}</p>
                          <figure class="fp_figure">
                            <img alt="Studdy X Pond Store" src="../../assets/image/history/history_studdy_1.jpg" decoding="async" data-nimg="1" class="fp_image" style="color: transparent;">
                          </figure>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>


            <li class="fp_year fp_year_left fp_year_left_jan2024">   <!-- 이게 Year의 한 세트 -->
              <div> <!-- 이게 Year의 Cover -->
                <ul>  <!-- 이게 Year -->
                  <li class="fp_month fp_month_left">  <!-- 이게 Month -->
                    JAN
                    <ul class="fp_contents">
                      <li class="fp_context">
                        <div class="fp_contextWidth fp_contextWidth_left">
                          <p class="fp_zeroSet">{{ $t('about.history.Jan2024.hisA') }}</p>
                          <figure class="fp_figure">
                            <img alt="Studdy X Pond Store" src="../../assets/image/history/history_hyundai_1.jpg" decoding="async" data-nimg="1" class="fp_image" style="color: transparent;">
                          </figure>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>

            <li class="fp_year fp_year_left fp_year_left_may2023">   <!-- 이게 Year의 한 세트 -->
              <div> <!-- 이게 Year의 Cover -->
                <ul>  <!-- 이게 Year -->
                  <li class="fp_month fp_month_left">  <!-- 이게 Month -->
                    MAY
                    <ul class="fp_contents">
                      <li class="fp_context">
                        <div class="fp_contextWidth fp_contextWidth_left">
                          <p class="fp_zeroSet">{{ $t('about.history.May2023.hisA') }}</p>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              <div class="fp_yearTitle">2023</div>    <!-- 연도표기 -->
              <div class="fp_yearpoint fp_yearpoint_left"><span></span></div>   <!-- 가운데 점 -->
            </li>




            <li class="fp_year fp_year_left fp_year_left_dec2021">   <!-- 이게 Year의 한 세트 -->

              <div> <!-- 이게 Year의 Cover -->
                <ul>  <!-- 이게 Year -->
                  <li class="fp_month fp_month_left">  <!-- 이게 Month -->
                    DEC
                    <ul class="fp_contents">
                      <li class="fp_context">
                        <div class="fp_contextWidth fp_contextWidth_left">
                          <p class="fp_zeroSet">{{ $t('about.history.Dec2021.hisA') }}</p>
                          <figure class="fp_figure">
                            <img alt="GS Retail X Pond Store" src="../../assets/image/history/history_gs_1.jpg" decoding="async" data-nimg="1" class="fp_image" style="color: transparent;">
                          </figure>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              <div class="fp_yearTitle">2021</div>    <!-- 연도표기 -->
              <div class="fp_yearpoint fp_yearpoint_left"><span></span></div>   <!-- 가운데 점 -->
            </li>
          </ul>


          <div class="fp_center">
            <span class="fp_history_line"></span>
          </div>

          <ul class="fp_right">    <!-- 이게 오른쪽 라인 -->
            <li class="fp_year fp_year_right fp_year_right_aug2024">
              <div class="fp_yearTitle">2024</div>
              <div class="fp_yearpoint fp_yearpoint_right"><span></span></div>

              <div>
                <ul class="fp_year_child">
                  <li class="fp_month fp_month_right">
                    AUG
                    <ul class="fp_contents">
                      <li class="fp_context">
                        <div class="fp_contextWidth fp_contextWidth_right">
                          <p class="fp_zeroSet">{{ $t('about.history.Aug2024.hisA') }}</p>
                          <figure class="fp_figure">
                            <img alt="Apple Korea X LIFFY" src="../../assets/image/history/history_apple_1.jpg" decoding="async" data-nimg="1" class="fp_image" style="color: transparent;">
                          </figure>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>

            <li class="fp_year fp_year_right fp_year_right_jul2024">
              <div>
                <ul class="fp_year_child">
                  <li class="fp_month fp_month_right">
                    JUL
                    <ul class="fp_contents">
                      <li class="fp_context">
                        <div class="fp_contextWidth fp_contextWidth_right">
                          <p class="fp_zeroSet">{{ $t('about.history.Jul2024.hisA') }}</p>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>

            <li class="fp_year fp_year_right fp_year_right_jun2024">
              <div>
                <ul class="fp_year_child">
                  <li class="fp_month fp_month_right">
                    JUN
                    <ul class="fp_contents">
                      <li class="fp_context">
                        <div class="fp_contextWidth fp_contextWidth_right">
                          <p class="fp_zeroSet">{{ $t('about.history.Jun2024.hisA') }}</p>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>

            <li class="fp_year fp_year_right fp_year_right_may2024">
              <div>
                <ul class="fp_year_child">
                  <li class="fp_month fp_month_right">
                    MAY
                    <ul class="fp_contents">
                      <li class="fp_context">
                        <div class="fp_contextWidth fp_contextWidth_right">
                          <p class="fp_zeroSet">{{ $t('about.history.May2024.hisA') }}</p>
                          <figure class="fp_figure">
                            <img alt="Apple Korea X LIFFY" src="../../assets/image/history/history_slickdeals_1.jpg" decoding="async" data-nimg="1" class="fp_image" style="color: transparent;">
                          </figure>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>

            <li class="fp_year fp_year_right fp_year_right_apr2024">
              <div>
                <ul class="fp_year_child">
                  <li class="fp_month fp_month_right">
                    APR
                    <ul class="fp_contents">
                      <li class="fp_context">
                        <div class="fp_contextWidth fp_contextWidth_right">
                          <p class="fp_zeroSet">{{ $t('about.history.Apr2024.hisA') }}</p>
                          <figure class="fp_figure">
                            <img alt="Apple Korea X LIFFY" src="../../assets/image/history/history_studdy_1.jpg" decoding="async" data-nimg="1" class="fp_image" style="color: transparent;">
                          </figure>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>

            <li class="fp_year fp_year_right fp_year_right_feb2024">
              <div>
                <ul class="fp_year_child">
                  <li class="fp_month fp_month_right">
                    FEB
                    <ul class="fp_contents">
                      <li class="fp_context">
                        <div class="fp_contextWidth fp_contextWidth_right">
                          <p class="fp_zeroSet">{{ $t('about.history.Feb2024.hisA') }}</p>
                          <figure class="fp_figure">
                            <img alt="Apple Korea X LIFFY" src="../../assets/image/history/history_dfu_1.jpg" decoding="async" data-nimg="1" class="fp_image" style="color: transparent;">
                          </figure>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>

            <li class="fp_year fp_year_right fp_year_right_jan2024">
              <div>
                <ul class="fp_year_child">
                  <li class="fp_month fp_month_right">
                    JAN
                    <ul class="fp_contents">
                      <li class="fp_context">
                        <div class="fp_contextWidth fp_contextWidth_right">
                          <p class="fp_zeroSet">{{ $t('about.history.Jan2024.hisA') }}</p>
                          <figure class="fp_figure">
                            <img alt="Apple Korea X LIFFY" src="../../assets/image/history/history_hyundai_1.jpg" decoding="async" data-nimg="1" class="fp_image" style="color: transparent;">
                          </figure>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>

            <li class="fp_year fp_year_right fp_year_right_may2023">
              <div class="fp_yearTitle">2023</div>
              <div class="fp_yearpoint fp_yearpoint_right"><span></span></div>

              <div>
                <ul class="fp_year_child">
                  <li class="fp_month fp_month_right">
                    MAY
                    <ul class="fp_contents">
                      <li class="fp_context">
                        <div class="fp_contextWidth fp_contextWidth_right">
                          <p class="fp_zeroSet">{{ $t('about.history.May2023.hisA') }}</p>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>


            <li class="fp_year fp_year_right fp_year_right_mar2023">
              <div>
                <ul class="fp_year_child">
                  <li class="fp_month fp_month_right">
                    MAR
                    <ul class="fp_contents">
                      <li class="fp_context">
                        <div class="fp_contextWidth fp_contextWidth_right">
                          <p class="fp_zeroSet">{{ $t('about.history.Mar2023.hisA') }}</p>
                          <figure class="fp_figure">
                            <img alt="Apple Korea X LIFFY" src="../../assets/image/history/history_mil_1.jpg" decoding="async" data-nimg="1" class="fp_image" style="color: transparent;">
                          </figure>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>

            <li class="fp_year fp_year_right fp_year_right_mar2022">
              <div class="fp_yearTitle">2022</div>
              <div class="fp_yearpoint fp_yearpoint_right"><span></span></div>

              <div>
                <ul class="fp_year_child">
                  <li class="fp_month fp_month_right">
                    MAR
                    <ul class="fp_contents">
                      <li class="fp_context">
                        <div class="fp_contextWidth fp_contextWidth_right">
                          <p class="fp_zeroSet">{{ $t('about.history.Mar2022.hisA') }}</p>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>



            <li class="fp_year fp_year_right fp_year_right_dec2021">
              <div class="fp_yearTitle">2021</div>
              <div class="fp_yearpoint fp_yearpoint_right"><span></span></div>

              <div>
                <ul class="fp_year_child">
                  <li class="fp_month fp_month_right">
                    DEC
                    <ul class="fp_contents">
                      <li class="fp_context">
                        <div class="fp_contextWidth fp_contextWidth_right">
                          <p class="fp_zeroSet">{{ $t('about.history.Dec2021.hisA') }}</p>
                          <figure class="fp_figure">
                            <img alt="Apple Korea X LIFFY" src="../../assets/image/history/history_gs_1.jpg" decoding="async" data-nimg="1" class="fp_image" style="color: transparent;">
                          </figure>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>

            <li class="fp_year fp_year_right fp_year_right_jun2021">
              <div>
                <ul class="fp_year_child">
                  <li class="fp_month fp_month_right">
                    JUN
                    <ul class="fp_contents">
                      <li class="fp_context">
                        <div class="fp_contextWidth fp_contextWidth_right">
                          <p class="fp_zeroSet">{{ $t('about.history.Jun2021.hisA') }}</p>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>


          </ul>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FootPrint',
  data(){
    return{
      
    }
  }
}
</script>

<style>
figure {
  margin: 0;
  display: block;
  padding: 0%;
  border: 0ch;
  font: inherit;
}
.fp_title {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 64px;
}
.fp_outerDiv {
  position: relative;
  width: 100%;
}
.fp_innerDiv {
  position: relative;
  display: flex;
}
.fp_contents {
  margin-top: 25px;
  padding-left: 0;
}
.fp_zeroSet {
  padding: 0;
  margin: 0;
  border: 0;
  font: inherit;
  vertical-align: top;
}
.fp_figure {
  margin-top: 20px;
}
.fp_yearTitle {
  position: relative;
  width: 100%  ;
  padding-left: 0;
  margin-bottom: 70px;
  font-size: 35px;
  font-weight: 900;
}
.fp_context {
  color: #083400;
  font-size: 20px;
  font-weight: 500;
}
.fp_contextWidth {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.fp_contextWidth_left {
  align-items: flex-end;
}
.fp_contextWidth_right {
  align-items: flex-start;
}
.fp_image {
  display: block;
  width: 600px;
  height: 340px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.fp_yearpoint {
  display: block;
  -webkit-box-pack: center;
  justify-content: center;
  position: absolute;
  top: 0.5vw;
  z-index: 5000;
}
.fp_yearpoint_left {
  right: -48px;
}
.fp_yearpoint_right {
  left: -48px;
}
.fp_yearpoint span {
  width: 32px;
  height: 32px;
  display: block;
  background-image: url("../../assets/icon/flower_Fill.svg");
}  
.fp_left {
  position: relative;
  width: 50%;
  padding-right: 32px; 
}
.fp_year {
  display: flex;
  color: #87ea5c;
  position: relative;
}
.fp_year_left {
  align-items: flex-end;
  flex-direction: column-reverse;
  text-align: right;
}
.fp_year_left_jul2024 {
  margin-top: 278px;
}
.fp_year_left_jun2024 {
  margin-top: 70px;
}
.fp_year_left_apr2024 {
  margin-top: 314px;
}
.fp_year_left_jan2024 {
  margin-top: 70px;
}
.fp_year_left_may2023 {
  margin-top: 90px;
}
.fp_year_left_dec2021 {
  margin-top: 824px;
}
.fp_year_right {
  align-items: flex-start;
  flex-direction: column; 
  text-align: left;
}
.fp_year_right_aug2024 {
  margin-top: 0px;
}
.fp_year_right_jul2024 {
  display: none;
  margin-top: 70px;
}
.fp_year_right_jun2024 {
  display: none;
  margin-top: 70px;
}
.fp_year_right_may2024 {
  margin-top: 70px;
}
.fp_year_right_apr2024 {
  display: none;
  margin-top: 70px;
}
.fp_year_right_feb2024 {
  margin-top: 70px;
}
.fp_year_right_jan2024 {
  display: none;
  margin-top: 70px;
}
.fp_year_right_may2023 {
  display: none;
  margin-top: 70px;
}
.fp_year_right_mar2023 {
  margin-top: 558px;
}
.fp_year_right_mar2022 {
  margin-top: 90px;
}
.fp_year_right_dec2021 {
  display: none;
  margin-top: 70px;
}
.fp_year_right_jun2021 {
  margin-top: 640px;
}
.fp_month {
  font-size: 25px;
  font-weight: 800;
  position: relative;
  display: block;
}
.fp_month_left {
  padding-left: 5.55vw;
}
.fp_month_right {
  padding-right: 5.55vw;
}
.fp_center {
  position: absolute;
  display: block;
  padding-top: 26px;
  left: 50%;
  transform: translate(-50%);
  height: 100%;
  padding-bottom: 0px;
}
.fp_history_line {
  position: relative;
  display: block;
  height: 100%;
  border-right: 2px solid #87ea5c;
}
.fp_right {
  position: relative;
  width: 50%;
  padding-left: 32px;
}
.fp_year_child {
  padding-left: 0;
  padding-right: 2rem;
}
@media screen and (max-width: 1500px) {
  .fp_image {
    width: 800px;
    height: 456px;
  }
  .fp_year_left {
    display: none;
  }
  .fp_left {
    width: 10%;
  }
  .fp_center {
    left: 10%;
  }
  .fp_right {
    width: 90%
  }
  .fp_year_right_jul2024,
  .fp_year_right_jun2024,
  .fp_year_right_may2024,
  .fp_year_right_apr2024,
  .fp_year_right_feb2024,
  .fp_year_right_jan2024,
  .fp_year_right_may2023,
  .fp_year_right_mar2023,
  .fp_year_right_mar2022,
  .fp_year_right_dec2021,
  .fp_year_right_jun2021 {
    display: block !important;
    margin-top: 70px !important;
  }
}
@media screen and (max-width: 1080px) {
  .fp_image {
    width: 600px;
    height: 340px;
  }
}
@media screen and (max-width: 767px) {
  .fp_image {
    width: 480px;
    height: 272px;
  }
}
@media screen and (max-width: 660px) {
  .fp_image {
    width: 360px;
    height: 204px;
    /* display: none; */
  }
}

@media screen and (max-width: 490px) {
  .fp_title {
    font-size: 20px;
  }
  .fp_yearTitle {
    margin-bottom: 32px;
    font-size: 18px;
  }
  .fp_month {
    font-size: 15px;
  }
  .fp_contents {
    margin-top: 12px;
  }
  .fp_context {
    font-size: 12px;
  }
  .fp_figure {
    margin-top: 12px;
  }
  .fp_image {
    width: 100%;
    height: 100%;
  }
  .fp_year_right_jul2024,
  .fp_year_right_jun2024,
  .fp_year_right_may2024,
  .fp_year_right_apr2024,
  .fp_year_right_feb2024,
  .fp_year_right_jan2024,
  .fp_year_right_may2023,
  .fp_year_right_mar2023,
  .fp_year_right_mar2022,
  .fp_year_right_dec2021,
  .fp_year_right_jun2021 {
    margin-top: 32px !important;
  }
  .fp_left {
    padding-right: 0px; 
  }
  .fp_center {
    padding-top: 20px;
  }
  .fp_right {
    padding-left: 18px;
  }
  .fp_yearpoint span {
    width: 20px;
    height: 20px;
  }
  .fp_yearpoint_right {
    left: -28px;
  }
  .fp_year_child {
    padding-right: 0px;
  }
  .fp_month_right {
    padding-right: 10vw;
  }
  .fp_image {
    border-radius: 10px;
  }
}
</style>