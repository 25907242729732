<template>
  <div>
    <div class="d-flex w-100 flex-column">
      <div class="container bs_is_title">
        <sectionTitle :msg="$t('business.itemsSection.title')" :margin="30" :link="storelink"/>
        <p class="bs_is_desc">{{ $t('business.itemsSection.description') }}</p>
        <p class="stLinkMin">
          <a :href="storelink" target="_blank" class="stAllBtn">See All &gt;</a>
        </p>
      </div>
    </div>

    <div>
      <div class="ItemslideWrap">
        <ul class="ItemimgSlide Itemoriginal">
          <li v-for="(item, index) in ItemoriginalSlides" :key="index" :style="{backgroundImage: 'url(' + item.image + ')'}"></li>
        </ul>
        <ul class="ItemimgSlide Itemclone">
          <li v-for="(item, index) in ItemclonedSlides" :key="index" :style="{backgroundImage: 'url(' + item.image + ')'}"></li>
        </ul>
      </div>
    </div>
  </div>
  
</template>

<script>
import { ref, onMounted } from 'vue';
import sectionTitle from '../common/sectionTitle.vue';

export default {
  name: 'Itemslide',
  data() {
    const ItemoriginalSlides = ref([
      {
        id: 1,
        image: require('../../assets/image/items/items_01.png')
      },
      {
        id: 2,
        image: require('../../assets/image/items/items_02.png')
      },
      {
        id: 3,
        image: require('../../assets/image/items/items_03.png')
      },
      {
        id: 4,
        image: require('../../assets/image/items/items_04.png')
      },
      {
        id: 5,
        image: require('../../assets/image/items/items_05.png')
      },
      {
        id: 6,
        image: require('../../assets/image/items/items_06.png')
      },
      {
        id: 7,
        image: require('../../assets/image/items/items_07.png')
      },
            {
      id: 8,
        image: require('../../assets/image/items/items_08.png')
      },
      {
        id: 9,
        image: require('../../assets/image/items/items_09.png')
      },
      {
        id: 10,
        image: require('../../assets/image/items/items_10.png')
      },
      {
        id: 11,
        image: require('../../assets/image/items/items_11.png')
      },
      {
        id: 12,
        image: require('../../assets/image/items/items_12.png')
      },
      {
        id: 13,
        image: require('../../assets/image/items/items_13.png')
      },
      {
        id: 14,
        image: require('../../assets/image/items/items_14.png')
      },
      {
        id: 15,
        image: require('../../assets/image/items/items_15.png')
      },
      {
        id: 16,
        image: require('../../assets/image/items/items_16.png')
      }
    ]);
    const ItemclonedSlides = ref([...ItemoriginalSlides.value]);

    onMounted(() => {
      // 복제본 추가
      document.querySelector('.ItemslideWrap').appendChild(document.querySelector('.Itemclone'));

      // 원본, 복제본 위치 지정
      // document.querySelector('.ItemimgSlide').style.width = document.querySelector('.ItemimgSlide').offsetWidth + 'px';
      document.querySelector('.ItemimgSlide').style.width = '4160px';
    });

    return {
      storelink: 'https://www.roblox.com/catalog?Category=1&CreatorName=lily%20%26%20liffy&salesTypeFilter=1&SortType=2&SortAggregation=3',
      ItemoriginalSlides,
      ItemclonedSlides
    };
  },
  components: {
    sectionTitle
  }
};
</script>

<style>
/* reset */
.bs_is_title {
  flex-direction: column;
  text-align: left;
  margin-bottom: 56px;
}
.bs_is_desc {
  font-size: 20px;
  font-weight: 500;
}
.stLinkMin {
  display: none;
}

/* 슬라이드 */
.ItemslideWrap {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  height: 240px;
  overflow: hidden;
}
.ItemslideWrap .ItemimgSlide {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0;
  margin-bottom: 0;
}
.ItemslideWrap .ItemimgSlide.Itemoriginal {
  animation: 40s linear 0s infinite reverse forwards running Itemslide01;
}
.ItemslideWrap .ItemimgSlide.Itemclone {
  animation: 40s linear 0s infinite reverse none running Itemslide02;
}
.ItemslideWrap .ItemimgSlide li {
  width: 240px;
  height: 240px;
  border-radius: 50px;
  margin-right: 20px;
  background-color: #ecefeb;
}

@media screen and (max-width: 490px) {
  .ItemslideWrap {
    height: 112px;
  }
  .bs_is_title {
    margin-bottom: 24px;
  }
  .bs_is_desc {
    font-size: 12px;
  }
  .stLinkMin {
    display: contents;
  }
  .ItemslideWrap .ItemimgSlide li {
    width: 112px;
    height: 112px;
    border-radius: 20px;
    margin-right: 8px;
    background-size: cover;
  }
}

/* 애니메이션 */
@keyframes Itemslide01 {
  0% { transform: translateX(0); }
  50% { transform: translateX(-100%); }
  50.01% { transform: translateX(100%); }
  100% { transform: translateX(0); }
}
@keyframes Itemslide02 {
  0% { transform: translateX(0); }
  100% { transform: translateX(-200%); }
}
</style>