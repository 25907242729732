<template>
  <div>
    <div class="d-flex w-100 flex-column bs_git_bg">
      <div class="container bs_engaging_container">
        <div class="bs_git_titleArea">
          <img :src="flowerSVG" class="bs_git_icon"/>
          <img :src="flowerSVG" class="bs_git_icon bs_git_tilt bs_trusted_mo"/>
          <img :src="flowerSVG" class="bs_git_icon bs_trusted_tab"/>
          <router-link to="/contact" class="bs_git_title">Get In Touch</router-link>
          <img :src="flowerSVG" class="bs_git_icon bs_trusted_tab"/>
          <img :src="flowerSVG" class="bs_git_icon bs_git_tilt bs_trusted_mo"/>
          <img :src="flowerSVG" class="bs_git_icon"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GetInTouch',
  data(){
    return{
      flowerSVG: require('../../assets/icon/flower.svg'),
    }
  }
}
</script>

<style>
.bs_git_bg {
  background-color: #87ea5c;
  padding: 95px 0px;
}
.bs_git_titleArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.bs_git_title {
  width: 360px;
  height: 76px;
  display: flex;
  color: #083400;
  font-size: 30px;
  font-weight: 700;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  margin: auto 40px;
  border: 2px solid #083400;
  border-radius: 50px;
  transition: 0.3s;
}
.bs_git_title:hover {
  color: #87ea5c;
  background-color: #083400;
}
.bs_git_icon {
  filter: invert(12%) sepia(30%) saturate(6143%) hue-rotate(80deg) brightness(96%) contrast(103%);
  width: 30px;
  height: 30px;
  margin: auto 20px;
}
.bs_git_tilt {
    transform: rotate(45deg);
}

@media (max-width: 992px) {
  .bs_trusted_tab {
    display: none;
  }
}
@media (max-width: 768px) {
  .bs_trusted_mo {
    display: none;
  }
}
@media screen and (max-width: 490px) {
  .bs_git_bg {
    padding: 56px 0px;
  }
  .bs_git_title {
    width: 176px;
    height: 32px;
    font-size: 15px;
    border-radius: 25px;
    margin: auto 16px;
  }
  .bs_git_icon {
    width: 20px;
    height: 20px;
    margin: auto 16px;
  }
}
</style>