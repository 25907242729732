import { createWebHistory, createRouter } from "vue-router";
import Main from './components/pages/main.vue';
import About from './components/pages/about.vue';
import Business from './components/pages/business.vue';
import Contact from './components/pages/contact.vue';
import Policy from './components/pages/policy.vue';

const routes = [
  {
    path: "/",
    component: Main,
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/business",
    component: Business,
  },
  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "/policy",
    component: Policy,
  },


];

const router = createRouter({
  history: createWebHistory(), 
  routes,
  scrollBehavior() {
    return { top:0 }
  }
});

export default router;